<template>
	<div class="wrap">
		<div class="header">重发产品</div>
		<SeCate v-show="!isForm" :cat_id="form.cat_id" @eventCateList="handleGetCate" />
		<FillForm v-show="isForm" :revForm="form" :cate="cate" @eventToCate="handleToCate" @eventSubmit="handleSubmit"
			:styleList="styleList" />
	</div>
</template>

<script>
	import SeCate from '@/components/Product/SeCate'
	import FillForm from '@/components/Product/FillForm'
	import Axios from 'axios'
	export default {
		data() {
			return {
				isForm: true,
				cate: {
					id: [],
					name: []
				},
				form: {
					user_id: this.$store.state.SELLER_ID,
				},
				styleList: []
			}
		},
		created() {
			this.$axios.post('/getGoodsInfo', {
				id: this.$route.query.id
			}).then(data => {
				let {
					goods,
					goodsphoto,
					goodsstyle
				} = data
				this.form = {
					cat_id: goods.catid,
					title: goods.title,
					description: goods.description,
					keywords: goods.keywords,
					brand_id: goods.brand_id,
					model: goods.model,
					seller_catid:goods.seller_catid,
					style_id: [],
					value: [],
					add_style: [],
					add_value: [],
					money: goods.money,
					thumb: goods.thumbs,
					photo: goodsphoto.map(item => item.photo),
					content: goods.content,
					util: goods.util,
					stock:goods.stock
				}
				goodsstyle.forEach(item => {
					this.styleList.push({
						id: item.style_id,
						value: item.value
					})
				})
			})
		},
		components: {
			SeCate,
			FillForm
		},
		methods: {
			handleGetCate(data) {
				this.cate = data
				this.isForm = true
			},
			handleToCate() {
				this.isForm = false
			},
			handleSubmit(form) {
				form.user_id=this.$store.state.SELLER_ID;
				Axios.post('/addGoods',  this.$qs.stringify(form)).then(data => {
					if (data.data.code == 200) {
						this.$alert(data.data.msg, '提示', {
							type: 'success',
							callback: action => {
								this.$router.push('/product')
							}
						})
					}else{
						this.$alert(data.data.msg, '提示', {
							type: 'error',
							callback: action => {}
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.header {
		padding: 0 28px;
		height: 77px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		font-size: 18px;
		color: #000000;
		align-items: center;
		font-weight: 700;
	}

	.header::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0e69db;
		margin-right: 10px;
	}

	.content {
		padding: 0 28px;
		color: #333;
	}
</style>
